import {fetchRequest, urlNtsServices} from './index';
import {AUTH_HEADER_PREFIX, LS_ACCESS_TOKEN} from "../../helpers/constants";

// Describes URL params for /services/reporting/{type}.json requests
export interface AdminAnalyticsPayload {
    type: string;
    groupBy: string;
    fromDate: string;
    toDate: string;
}

// Describes service response
//      from /services/reporting/orders.json (submitDate) or
//      from /services/reporting/quotes.json (quoteDate)
export interface AdminAnalyticsResponse {
    quoteDate?: number;
    submitDate?: number;
    groupName: string;
    count: number;
}

/**
 * Calls webapp-api to retrieve reporting data for a given time range
 */
export const AdminReportingService = {
    getAnalyticsResults: (payload:AdminAnalyticsPayload) => {
        const request = new Request(`${urlNtsServices}/reporting/${payload.type}?groupBy=${payload.groupBy}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
            }
        });
        return fetchRequest(request);
    },
    getDownloadResults: (input:string) => {
        const request = new Request(`${urlNtsServices}/reporting/order/find?start=0&count=10000&input=${input}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
            }
        });
        return fetchRequest(request);
    },
    sendEmailResults: (input:number) => {
        const request = new Request(`${urlNtsServices}/email/confirmation?teamOrderId=${input}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `${AUTH_HEADER_PREFIX} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
            }
        });
        return fetchRequest(request);
    }

};
