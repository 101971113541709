import React from 'react';
import { Row, Col, Grid } from 'react-bootstrap';
import {Link} from 'react-router-dom';

import {akamaiImageUrl} from "../../../App";
import {isDealerRole} from "../../helpers/userProfileHelpers";
import {setPreloginUrl} from "../../../auth/utils/helpers";
import {authService} from "../../../App";
import {connect} from "react-redux";

//TODO: Re-activate and re-point links once react version of FAQ pages are complete
// const PLACEHOLDER_LINK = "/en/en_US/nts/";

const Footer = () => {

  const register = () => {
    setPreloginUrl(window.location.href);
    authService.signinRedirect();
  };
  return (
    <div id="nts-footer" className="nts-footer-outer-container">
      <Grid className="nts-footer-inner-container">
        <Row>
          <Col xs={12} sm={2} className="primary-links-col">
            <a className="nts-footer-title-link" href="https://www.nike.com">NIKE.COM</a>
          </Col>
          <Col xs={12} sm={2} className="nts-footer-links-col">
            <button className="nts-footer-title-link" onClick={register}>BECOME A MEMBER</button>
          </Col>
          <Col xs={12} sm={2} className="nts-footer-links-col">
            <a className="nts-footer-title-link nts-contact-link" href={`mailto:myniketeam@nike.com`}>CONTACT US</a>
          </Col>
          <Col xs={12} sm={2} className="nts-footer-links-col">
          <Link className="nts-footer-title-link" to="/en/en_US/nts/catalogs">DOWNLOAD A CATALOG</Link>
          </Col>
          <Col xs={12} sm={2} className="nts-footer-links-col">
            <Link className="nts-footer-title-link" to="/en/en_US/nts/help/faq">HELP</Link>
          </Col>

          {/*TODO: Re-activate and re-point links once react version of FAQ pages are complete
          <Col xs={12} sm={2} className="nts-footer-links-col">
            <div className="nts-footer-title">Product Info</div>
            <a className="nts-footer-link" href={PLACEHOLDER_LINK}>Custom Logos</a>
            <a className="nts-footer-link" href={PLACEHOLDER_LINK}>Embellishments</a>
            <a className="nts-footer-link" href={PLACEHOLDER_LINK}>Sizing Information</a>
          </Col>
          <Col xs={12} sm={2} className="nts-footer-links-col">
            <div className="nts-footer-title">Customization</div>
            <a className="nts-footer-link" href={PLACEHOLDER_LINK}>Simple Customization</a>
            <a className="nts-footer-link" href={PLACEHOLDER_LINK}>Build to Order</a>
          </Col>
          <Col xs={12} sm={2} className="nts-footer-links-col">
            <div className="nts-footer-title">Ordering</div>
            <a className="nts-footer-link" href={PLACEHOLDER_LINK}>How to Order</a>
            <a className="nts-footer-link" href={PLACEHOLDER_LINK}>Submitting a Quote</a>
            <a className="nts-footer-link" href={PLACEHOLDER_LINK}>Contact a Dealer</a>
            <a className="nts-footer-link" href={PLACEHOLDER_LINK}>Shipping and Delivery</a>
          </Col>
          <Col xs={12} sm={4} className="nts-footer-social-col">
            <a href={PLACEHOLDER_LINK}><i className="g72-twitter" /></a>
            <a href={PLACEHOLDER_LINK}><i className="g72-facebook" /></a>
            <a href={PLACEHOLDER_LINK}><i className="g72-youtube" /></a>
            <a href={PLACEHOLDER_LINK}><i className="g72-instagram" /></a>
          </Col>*/}

        </Row>
        <Row className="nts-copyright-row">
          <Col xs={12} className="hidden-xs"><div className="nts-footer-separator"></div></Col>
          <Col xs={12} sm={6} className="nts-copyright-col">
            <span className="locale-info">
              <img src={akamaiImageUrl + '/logos/us_sml.png'} alt="United States" width="14" height="10" />
              United States
            </span>
            <span className="nts-footer-copyright">&copy; {new Date().getFullYear()} Nike, Inc. All Rights Reserved</span>
          </Col>
          <Col className="nts-global-nike-links" xs={12} sm={6}>
            {/*TODO: Re-activate and re-point links once react version of FAQ pages are complete
            <a href="https://niketeam.nike.com/US/en_US/p/help">Guides</a>
            */}
            <a href="https://agreementservice.svs.nike.com/us/en_us/rest/agreement?agreementType=termsOfUse&uxId=com.nike.commerce.nikedotcom.web&country=US&language=en&requestType=redirect">Terms of Use</a>
            <a href="https://agreementservice.svs.nike.com/us/en_us/rest/agreement?agreementType=privacyPolicy&uxId=com.nike.commerce.nikedotcom.web&country=US&language=en&requestType=redirect">Nike Privacy Policy</a>
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userAuthorities: state.userState.authorities
  }
};

export default connect(mapStateToProps)(Footer);
